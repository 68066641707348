/**
 * ./composables/notifications.ts
 */

const config = useRuntimeConfig();

export const useNotifications = () => {

    const getNotifications = async (userId: string) => {
        const { data } = await useApiFetch(`/api/users/${userId}/notifications`);

        return data.value?.data;
    }

    const getUnreadNotifications = async (userId: string) => {
        const { data } = await useApiFetch(`/api/users/${userId}/notifications/unread`);

        return data.value?.data;
    }

    const markAllNotificationsAsRead = async (userId: string) => {
        const { data } = await useApiFetch(`/api/users/${userId}/notifications/read-all`, {
            method: 'PUT',
        });
    }

    return {
        getNotifications,
        getUnreadNotifications,
        markAllNotificationsAsRead
    };
}

