/**
 * ./stores/Notifications.ts
 */

export const useNotificationStore = defineStore('notifications', () => {
    const { user } = useAuth()
    const {
        getUnreadNotifications,
        markAllNotificationsAsRead
    } = useNotifications();

    const notifications = ref<INotification[]>([]);
    const unreadNotifications = ref<INotification[]>([]);

    const loadUnreadNotifications = async (): INotification[] => {
        let newNotifications = await getUnreadNotifications(user.id);

        return addNotifications(newNotifications);

    }

    const markAllAsRead = (): INotification[] => {
        markAllNotificationsAsRead(user.id);
        clearUnreadNotifications();

        return notifications.value;
    }

    // Replaces existing notifications
    const setNotifications = (newNotifications: INotification[]): INotification[] => {
        notifications.value = [];
        newNotifications.forEach((n) => {
            addNotification(n);
        });

        return unref(notifications);
    }

    // Appends notifications to existing notifications
    const addNotifications = (newNotifications: INotification[]): INotification[] => {
        newNotifications.forEach((n) => {
            addNotification(n);
        });

        return notifications.value;

    }

    // Add a single notification
    const addNotification = (notification: INotification): INotification => {
        if(! notification ) {
            return null;
        }

        notifications.value.push(notification);
        if(! notification.read_at || notification.read_at === null) {
            unreadNotifications.value.push(notification);
        }

        return notification;

    }

    const clearUnreadNotifications = (): void => {
        unreadNotifications.value = [];
    }

    return {
        notifications,
        loadUnreadNotifications,
        markAllAsRead,
        unreadNotifications,
        clearUnreadNotifications,
        addNotification,
        addNotifications,
        setNotifications,
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
