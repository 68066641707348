<template>
    <nav class="navbar navbar-expand-md navbar-light d-none d-md-flex" id="topbar">
        <div class="container-fluid">
            <form class="me-4 d-none d-md-flex w-50">
                <AccountSearch
                :clear-on-select="true"
                @update="(value) => goTo(value)"
            />
            </form>
            <div class="navbar-user">
                <NavigationSwsOnlineButton />

                <NotificationDropdown />

                <button type="button" @click="quickActivityForm.toggle()" class="btn btn-sm btn-secondary d-block d-md-inline-block me-4">
                    <Icon icon="add"/><Icon icon="vital_signs"/>
                </button>

                <div class="dropdown">
                    <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="avatar avatar-sm dropdown-toggle">
                        <img v-if="user.photo" src="" alt="..." class="avatar-img rounded-circle">
                        <div v-else class="avatar-img avatar-initials rounded-circle bg-success text-white text-center">{{ $initials(user.first_name, user.last_name) }}</div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <hr class="dropdown-divider">
                        <button @click.prevent="logout" class="dropdown-item">Logout</button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
const quickActivityForm = useQuickActivityFormStore();
const { $sanctumAuth, $initials, $relativeDate } = useNuxtApp()
const { user } = useAuth()

const goTo = (account) => {
    if (account) {
        navigateTo('/accounts/'+account.id);
    }
}

const logout = async () => {
    await $sanctumAuth.logout(
        // optional callback function
    )
}
</script>
