<template>
    <div>
        <nav class="navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md" id="nav">
            <div class="container-fluid">
                <button type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <a class="navbar-brand" href="/">
                    <img src="/images/logo-c.svg" alt="Centric" class="navbar-brand-img mx-auto">
                </a>

                <!-- User xs -->
                <div class="navbar-user d-md-none">
                    <button type="button" @click="quickActivityForm.toggle()" class="btn btn-secondary d-block d-md-inline-block me-4">
                        <i class="fe fe-plus"></i> <i class="fe fe-activity"></i>
                    </button>
                    <div class="dropdown">
                        <a href="#" id="nav-user-xs" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle">
                            <div class="avatar avatar-sm">
                                <img v-if="user.photo" src="/images/logo-c.svg" class="avatar-img rounded-circle" alt="...">
                                <div v-else class="avatar-img avatar-initials rounded-circle bg-success text-white text-center">{{ $initials(user.first_name, user.last_name) }}</div>
                            </div>
                        </a>
                        <div aria-labelledby="nav-user-xs" class="dropdown-menu dropdown-menu-end">
                            <hr class="dropdown-divider">
                            <a href="#" @click.prevent="logout" class="dropdown-item">Logout</a>
                        </div>
                    </div>
                </div>

                <div id="main-nav" class="collapse navbar-collapse">
                    <!-- Search form <= sm -->
                    <form class="mt-4 mb-3 d-md-none">
                        <AccountSearch
                            :clear-on-select="true"
                            @update="(value) => goTo(value)"
                        />
                    </form>

                    <hr class="navbar-divider d-none d-md-block mt-0 mb-3">

                    <ul class="navbar-nav">
                        <li class="nav-item dropend">
                            <NuxtLink :to="{ name: 'dashboard' }" class="nav-link">
                                <Icon icon="home" class="fs-2" />
                                <span class="d-md-none">Dashboard</span>
                            </NuxtLink>
                        </li>
                        <li class="nav-item dropend">
                            <NuxtLink :to="{ name: 'activities' }" class="nav-link">
                                <Icon icon="vital_signs" class="fs-2" />
                                <span class="d-md-none">Activities</span>
                            </NuxtLink>
                        </li>
                        <li class="nav-item dropend">
                            <a href="#" id="nav-accounts" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" title="Accounts" class="nav-link dropdown-toggle">
                                <Icon icon="trip" class="fs-2" />
                                <span class="d-md-none">Accounts</span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="nav-accounts">
                                <li class="dropdown-header d-none d-md-block">
                                    <h6 class="text-uppercase mb-0">Accounts</h6>
                                </li>
                                <li>
                                    <NuxtLink :to="{ name: 'accounts' }" class="dropdown-item">View All</NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink :to="{ name: 'accounts-create' }" class="dropdown-item">Add new</NuxtLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul class="navbar-nav mt-auto mb-md-4">
                        <li class="nav-item dropup">
                            <a href="#" id="nav-accounts" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" title="Accounts" class="nav-link dropdown-toggle">
                                <Icon icon="settings" class="fs-2" />
                                <span class="d-md-none">Settings</span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="nav-accounts">
                                <li class="dropdown-header d-none d-md-block">
                                    <h6 class="text-uppercase mb-0">settings</h6>
                                </li>
                                <li>
                                    <NuxtLink :to="{ name: 'settings-reports' }" class="dropdown-item">Reports</NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink :to="{ name: 'settings-activity-categories' }" class="dropdown-item">Activity Categories</NuxtLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="form-check form-switch form-switch-sm d-none d-md-block">
                    <input class="form-check-input" type="checkbox" role="switch" id="displayModeSwitch" @change="toggleDisplayMode" :checked="user.display_mode == 'dark'">
                    <label class="form-check-label" for="displayModeSwitch"></label>
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup lang="ts">
const { $sanctumAuth, $initials } = useNuxtApp()
const { user } = useAuth()
const quickActivityForm = useQuickActivityFormStore();

const toggleDisplayMode = async () => {
    user.display_mode = user.display_mode == 'dark' ? 'light' : 'dark';
    useHead({
        htmlAttrs: {
            'data-bs-theme': user.display_mode ?? 'light',
        },
    })

    await useApiFetch('/api/profile', {
        method: 'put',
        body: {
            display_mode: user.display_mode
        }
    }, true);
};

const logout = async () => {
  await $sanctumAuth.logout(
    // optional callback function
  )
}

const goTo = (account) => {
    if (account) {
        navigateTo('/accounts/'+account.id);
    }
}
</script>
