<template>
    <div class="dropdown notifications me-4">
        <a href="#!" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            class="icon text-body navbar-user-link"
            :class="{ 'active': notifications.unreadNotifications.length > 0 }"
        >
            <Icon icon="notifications" />
        </a>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-card">
            <div class="card-header">
                <div class="row align-items-center justify-content-between">
                    <div class="col-auto">
                        <h4 class="card-header-title">Notifications</h4>
                    </div>
                    <div class="col-auto">
                        <button v-if="notifications.unreadNotifications.length"
                            class="btn btn-link text-body-primary"
                            @click="readAll()"
                        >
                            Mark all as read
                        </button>
                    </div>
                </div>

                <!-- <a href="#!" class="small text-white">View all</a> -->
            </div>
            <div v-if="notifications.unreadNotifications.length === 0" class="card-body text-center">
                Nothing to see at the moment
            </div>
            <ul class="list-group list-group-flush">
                <li v-for="notification in notifications.unreadNotifications" :key="notification.id"
                    :class="notificationBackground(notification)"
                    class="bg-gradient list-group list-group-flush list-group-activity border-bottom px-3">
                    <NuxtLink :to="{ name: 'activities-show', params: { id: notification.data.activity.id }}" class="list-group-item text-reset">
                        <div class="row">
                            <div class="col-auto">
                                <div class="avatar avatar-sm">
                                    <div class="avatar-img avatar-initials rounded-circle bg-info text-white text-center">
                                        <ActivityTypeIcon :type="{ id: notification.data.activity.type.id }" />
                                    </div>
                                </div>
                            </div>
                            <div class="col ms-n2 small">
                                <div class="fw-bold">
                                    {{ notification.data.description }}
                                </div>
                                <div>{{ notification.data.activity.category }}</div>
                                <div class="row justify-content-between text-muted">
                                    <div class="col-auto ">
                                        <Icon icon="timer" class="inline me-2" />
                                        <time datetime="2018-05-24">{{ $relativeDate(notification.created_at) }}</time>
                                    </div>
                                    <div class="col-auto">
                                        Click to view
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NuxtLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
const { $pusher, $sanctumAuth, $initials, $relativeDate } = useNuxtApp()
const { user } = useAuth()

const notifications = useNotificationStore();
const toasts = useToastsStore();

onMounted(async () => {
    notifications.loadUnreadNotifications();

    $pusher().private(`App.Users.User.${user.id}`)
        .notification((notification) => {
            notifications.addNotification(notification);

            switch(notification.data.status) {
                case 'action':
                    toasts.warning(notification.data.description);
                    break;
                case 'alert':
                    toasts.error(notification.data.description);
                    break;
                case 'info':
                    toasts.info(notification.data.description);
                    break;
                default:
                    toasts.info(notification.data.description);
                    break;
            }
        });
});

const notificationBackground = (notification: Notification) => {
    switch(notification.data.status) {
        case 'action':
            return 'bg-warning text-dark';
        case 'alert':
            return 'bg-danger';
        default:
            return '';
    }
}

const readAll = async () => {
    notifications.markAllAsRead();
}
</script>
