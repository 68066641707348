<template>
    <div>
        <NavigationMain />
        <div class="main-content">
            <NavigationTop />
            <div class="container-fluid pb-3">
                <slot />
            </div>
            <QuickActivityForm
                :open="quickActivityForm.show"
                @offcanvas:toggled="quickActivityForm.toggle()"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
const { user } = useAuth()
const quickActivityForm = useQuickActivityFormStore();

useHead({
    htmlAttrs: {
        'data-bs-theme': user.display_mode ?? 'light',
    },
})

// Fetch Site Settings
const settings = useApplicationSettingsStore();
const { data, error } = await useApi('settings', '/api/application/settings/');
settings.setSettings(data.value.settings);

</script>
