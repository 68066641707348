<template>
    <div ref="el" class="offcanvas offcanvas-end" tabindex="-1">
        <div class="offcanvas-header">
            <h3 class="offcanvas-title"><slot name="heading"></slot></h3>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
const { $bootstrap } = useNuxtApp();

const props = defineProps<{
    open?: boolean,
}>();

const el = ref();
let offCanvas:any;

const emit = defineEmits<{
    (event: 'offcanvas:toggled', open: boolean): void;
}>();

onMounted(() => {
    offCanvas = new $bootstrap.Offcanvas(el.value);
});

watch(() => props.open, (open) => {
    offCanvas.toggle();
});
</script>
